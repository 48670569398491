.spaces {
  background-color: #f3f4f6;

  h4 {
    background-color: #1e6196;
    color: #fff;
    height: 244px;
    letter-spacing: 12px;
  }

  .spacesWrpr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 300px;
    column-gap: 40px;
    row-gap: 36px;
    padding: 78px 0px 40px;
    height: 100%;

    .space {
      background-color: #fff;
      padding: 18px 20px;
      border-radius: 7px;
      border: 1px solid #fff;
      transition: all 0.2s;
      box-shadow: 0px 2px 6px -2px #00000040;
      text-decoration: none;
      color: #1e6196;

      &:hover {
        border: 1px solid #428fcc;
        box-shadow: -1px 12px 42px 0px #80a5c480;
      }

      .mainImg {
        background-color: white;
        border-radius: 4px;
        border: 1px solid rgb(128 165 196);
      }

      .spaceLine {
        padding: 0px 16px;
        margin-top: 28px;

        .lineSec {
          width: 50%;
          color: #1e6196;
          .label {
          }

          .value {
          }
        }
      }

      .featuresWrpr {
        border-top: 1px solid #d3e5f2;
        padding: 24px 16px 10px;

        .featureWrpr {
          width: 50%;
          color: #1e6196;
        }
      }
    }

    &::after {
      content: "";
      display: block;
    }
  }
}

@media screen and (max-width: 520px) {
  .spaces {
    h4 {
      height: 134px;

      div {
        font-size: 20px;
        letter-spacing: 4px;
      }
    }

    .spacesWrpr {
      grid-template-columns: 100%;
    }
  }
}
