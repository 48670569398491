.space {
  background-color: #f6f9fc;
  padding: 140px 0px 0px;

  .backButton {
    background-color: #ecf3f9;
    border: none;
    height: 48px;
    width: 105px;
    color: #5082ad;
    border-radius: 4px;
    text-decoration: none;

    &::before {
      content: "";
      height: 9px;
      width: 9px;
      background-color: transparent;
      display: block;
      transform: rotate(45deg);
      margin-right: 8px;
      border: 1px solid #1e6196;
      border-width: 0px 0px 2px 2px;
      cursor: pointer;
    }
  }

  .contentWrpr {
  }

  .leftSec {
    width: 54%;
    padding-bottom: 108px;

    img {
      background-color: #fff;
      width: 100%;
      padding: 10px;
      box-sizing: content-box;
      border-radius: 8px;
    }
  }

  .rightSec {
    width: 46%;
    align-items: flex-start;
    max-width: 480px;
    padding-left: 80px;
    color: #1e6196;
    padding-bottom: 108px;

    .bullet {
      background-color: #32ac54;
      color: #fff;
      border-radius: 50px;
      padding: 3px 14px;
    }

    .line {
      width: 100%;

      &.second {
        border-bottom: 1px solid #d3e5f2;
        padding-bottom: 30px;
      }

      .sec {
        width: 50%;
        text-transform: capitalize;
      }
    }

    .rentButton {
      background-color: #1e6196;
      width: 100%;
      color: #fff;
      border: none;
      border-radius: 4px;
      height: 48px;
    }
  }
}

@media screen and (max-width: 520px) {
  .space {
    .contentWrpr {
      flex-flow: column;

      .leftSec {
        width: 100%;
        padding-bottom: 28px;
      }

      .rightSec {
        width: 100%;
        padding-left: 0px;
        padding-bottom: 38px;
      }
    }
  }
}
