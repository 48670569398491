.landing {
  background-image: url("../../assets/images/tech-valley-landing-bg.png");
  background-repeat: no-repeat;
  background-position: center 0px;
  height: 820px;

  .content {
    max-width: 500px;

    h1,
    h4 {
      color: #fff;
    }

    h1 {
      line-height: 84px;
    }

    h4 {
      line-height: 34px;
    }
    a {
      width: 166px;
      height: 58px;
      border: none;
      border-radius: 4px;
      background-color: #428fcc;
      color: #ffffff;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &.contact {
        background-color: transparentize($color: #80a5c4, $amount: 0.75);
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .landing {
    height: initial;

    .content {
      padding-top: 80px;
      width: 100%;

      h1 {
        font-size: 44px;
        line-height: 58px;
        max-width: 262px;
        padding-top: 58px;
      }

      h4 {
        font-size: 18px;
        line-height: 26px;
        padding-top: 34px;
        max-width: 194px;
      }

      .buttonsWrpr {
        padding: 30px 0px 90px;
        flex-flow: column;

        a {
          width: 100%;
        }

        .contact {
          display: none;
        }
      }
    }
  }
}
