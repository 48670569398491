.footer {
  background-color: #1e6196;
  background-image: url("../../assets/images/triangle-background.png");
  background-position: top right;
  background-repeat: no-repeat;
  padding-top: 142px;
  color: #fff;

  .logoSec {
    padding-right: 12%;

    p {
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

  .navSec {
    margin-bottom: 80px;

    h5 {
      &::after {
        content: "";
        display: block;
        height: 1px;
        width: 50px;
        background-color: #fff;
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }

    a {
      display: block;
      text-decoration: none;
      color: #fff;
      line-height: 32px;
    }
  }

  .right {
    max-width: 364px;

    .inputWrpr {
      margin-top: 70px;

      input {
        width: 340px;
        height: 48px;
        border: 1px solid #fff;
        border-radius: 4px;
        background-color: transparent;
        color: #fff;
        padding: 0px 40px 0px 20px;

        &::placeholder {
          color: #fff;
        }
      }

      .subscribeBtn {
        background-color: transparent;
        border: 1px solid #fff;
        position: absolute;
        right: 38px;
        height: 12px;
        width: 12px;
        border-width: 0px 2px 2px 0px;
        transform: rotate(-45deg) translateY(-60%);
        top: 50%;
        padding: 0px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .footer {
    padding-top: 50px;
    .contentWrpr {
      flex-flow: column;

      img {
        // transform: scale(0.75);
      }

      h5 {
        font-size: 20px;
      }

      .inputWrpr {
        margin-top: 30px;
      }
    }

    .infoContentWrpr {
      flex-flow: column;

      p {
        margin-top: 22px;
        width: 100% !important;
      }
    }

    .right {
      .contentWrpr {
        // h5 {
        //   font-size: 20px;
        // }

        .inputWrpr {
          margin-top: 10px;
          input {
            width: 100%;
          }
        }
      }
    }
  }
}
