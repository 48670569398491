.features {
  background-color: #f6f9fc;
  height: 584px;

  h3 {
    color: #1e6196;
  }

  .listSec {
    &.small {
      width: 42%;
    }

    .line {
      height: 66px;

      &::before {
        content: "";
        display: block;
        height: 17px;
        width: 17px;
        background-color: #1e6196;
        margin-right: 34px;
        flex-shrink: 0;
      }

      .primary {
        width: 230px;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .features {
    height: initial;
    padding-bottom: 40px;

    h3 {
      font-size: 24px;
    }

    .listWrpr {
      flex-flow: column;

      .listSec {
        width: 100%;

        .line {
          height: 46px;
          font-size: 13px;
          width: 100%;

          &::before {
            content: "";
            display: block;
            height: 12px;
            width: 12px;
            background-color: #1e6196;
            margin-right: 34px;
            flex-shrink: 0;
          }

          .primary {
            width: initial;
          }

          img {
            margin-left: auto;
            width: 94px;
          }
        }

        &.directions {
          margin-top: 46px;

          .line {
            &.title {
              max-width: 90%;
              // text-align: center;
              line-height: 22px;
              margin-bottom: 20px;
            }
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}
