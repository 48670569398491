.about {
  height: 860px;

  .imageSection {
    width: 53.5%;

    .imgWrpr {
      display: inline-block;
      width: 100%;

      img {
        width: 100%;
      }

      &::before {
        content: "";
        // display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #d3e5f2;
        top: -28px;
        left: -28px;
        border-radius: 11px;
      }
    }

    .imgNavigationWrpr {
      position: absolute;
      right: -40px;
      bottom: 35px;

      .nav {
        height: 76px;
        width: 76px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 2px -2px 12px 0px rgb(128 165 196 / 25%);

        &.next {
          transform: rotate(180deg);
        }
      }
    }
  }

  .description {
    width: 46.5%;
    padding: 0px 0px 0px 95px;
    align-self: flex-start;
    top: 148px;

    h5 {
      color: #5082ad;
    }

    p {
      color: #18375e;
      line-height: 25px;
      max-width: 408px;

      &.subTitle {
        text-transform: uppercase;
        line-height: 35px;
        max-width: 100%;
      }
    }

    .viewButton {
      background-color: #1e6196;
      color: #fff;
      height: 58px;
      padding: 0px 30px;
      border-radius: 4px;
      border: none;
      letter-spacing: 0.8px;
    }
  }
}

// 5082AD

@media screen and (max-width: 520px) {
  .about {
    height: initial;

    .contentWrpr {
      padding-bottom: 80px;
      flex-flow: column;
      flex-direction: column-reverse;

      .imageSection {
        width: 100%;

        .imgWrpr {
          &::before {
            display: none;
          }
        }

        .imgNavigationWrpr {
          position: absolute;
          left: 50%;
          bottom: -8px;
          transform: translateX(-50%);
          right: initial;

          .nav {
            height: 46px;
            width: 46px;
          }
        }
      }

      .description {
        width: 100%;
        padding: 0px;
        top: initial;
        padding-top: 40px;

        h5 {
          font-size: 16px;
          margin-bottom: 20px !important;
        }

        p {
          font-size: 13px;
        }

        .subTitle {
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 30px !important;
        }

        .viewButton {
          margin-bottom: 80px;
          width: 100%;
        }
      }
    }
  }
}
