.siteMap {
  background-color: #f3f4f6;

  h4 {
    background-color: #1e6196;
    color: #fff;
    height: 244px;
    letter-spacing: 12px;
  }

  .imgSec {
    background-color: #fff;

    .siteMapImg {
      margin-top: -60px;
      max-width: 100%;
      width: 100%;
    }
  }

  .logoSec {
    // background-color: #f6f9fc;
    padding-top: 40px;
  }

  .logosHeader {
    font-size: 22px;
    color: #1e6196;
  }

  .logosWrpr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 18px;
    row-gap: 18px;
    padding: 0px 100px;
    margin: 38px 0px 96px;
    padding-bottom: 40px;

    .logoWrpr {
      background-color: #fff;
      aspect-ratio: 1.8/1;

      img {
        max-height: 80%;
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .siteMap {
    h4 {
      height: 134px;

      div {
        font-size: 20px;
        letter-spacing: 4px;
      }
    }

    .siteMapImg {
      margin-top: -34px;
    }

    .logosWrpr {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 8px;
      row-gap: 8px;
      padding: 0px;
      margin: 18px 0px;

      .logoWrpr {
      }
    }
  }
}
