.header {
  height: 100px;
  border-bottom: 1px solid rgba(74, 124, 189, 0.5);
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: transparent;
  transition: all 0.5s;

  &.dark {
    background-color: rgba(255, 255, 255, 0.85);

    .nav {
      color: #18375e;
    }
  }

  .mobileOpener {
    display: none;
  }

  .nav {
    color: #ffffff;
    text-decoration: none;
    padding: 0px 24px;
  }

  .contactBtn {
    background-color: #428fcc;
    color: #fff;
    height: 58px;
    width: 166px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    margin-left: 25px;
  }
}

@media screen and (max-width: 520px) {
  .header {
    height: 86px;

    img {
      width: 156px;
    }

    .mobileOpener {
      display: initial;
      border-radius: 6px;
      height: 3px;
      width: 30px;
      background-color: #fff;

      &::before {
        content: "";
        display: block;
        border-radius: 6px;
        height: 3px;
        width: 30px;
        background-color: #fff;
        position: absolute;
        top: -10px;
        transition: transform 0.2s;
      }

      &::after {
        content: "";
        display: block;
        border-radius: 6px;
        height: 3px;
        width: 30px;
        background-color: #fff;
        position: absolute;
        bottom: -10px;
        transition: transform 0.2s;
      }

      &.open {
        background-color: transparent;
        &::before {
          top: 0px;
          transform: rotate(45deg);
        }

        &::after {
          bottom: 0px;
          transform: rotate(-45deg);
        }
      }
    }

    &.dark {
      .nav {
        color: #fff;
      }

      .mobileOpener {
        background-color: #18375e;

        &.open {
          background-color: transparent;
        }

        &::before {
          background-color: #18375e;
        }
        &::after {
          background-color: #18375e;
        }
      }
    }

    .navsWrpr {
      // Uncomment
      display: none;
      flex-flow: column;
      top: 86px;
      left: 0px;
      position: absolute;
      background-color: #18385efa;
      width: 100%;
      padding: 30px 0px;

      &.open {
        display: flex;
      }

      .nav {
        // color: #18375e;
        padding: 20px 0px;
      }

      .contactBtn {
        margin-left: 0px;
        height: 44px;
        font-size: 14px;
        margin-top: 36px;
        margin-bottom: 24px;
      }
    }
  }
}
