.location {
  .headerWrpr {
    // height: 228px;
    background-color: #f3f4f6;
    color: #18375e;

    .sub {
      color: #1e6196;
    }

    .headerInnerWrpr {
      border-top: 1px solid #8cbbe0;
      margin-bottom: 100px;
      padding-top: 36px;
      padding-bottom: 116px;
    }
  }

  .mapWrpr {
    background-color: #18375e;
    // height: 200px;
    padding: 50px 0px;
    text-align: center;

    .button {
      background-color: #1e6196;
      height: 62px;
      width: 194px;
      margin: 60px auto 0px;
      color: #fff;
      border-radius: 4px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 520px) {
  .location {
    .headerWrpr {
      .headerInnerWrpr {
        flex-flow: column;

        p {
          font-size: 20px;
          text-align: center;

          &.sub {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
