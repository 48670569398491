.contact {
  background-image: url("../../assets/images/triangle-background.png");
  background-position: top right;
  background-repeat: no-repeat;
  // height: 500px;
  background-color: #f6f9fc;
  padding-top: 100px;

  .formWrpr {
    width: 100%;
    max-width: 1194px;
    margin: 0px auto;
    padding: 94px 70px;

    .formInnerWrpr {
      background-color: #fff;
      padding: 72px 64px;
      box-shadow: 0px 2px 6px -2px rgb(0 0 0 / 25%);
      border-radius: 8px;

      &::before {
        content: "";
        background-image: url("../../assets/images/mail0icon.png");
        height: 119px;
        width: 117px;
        position: absolute;
        top: 0px;
        // translate: 0px -50%;
        transform: translateY(-50%);
      }

      .infoWrpr {
        width: 50%;

        h3 {
          color: #18375e;
          margin: 40px 0px 46px;
        }

        p {
          color: #1e6196;

          &.label {
            margin-bottom: 5px;
          }
          &.value {
            margin-bottom: 32px;
          }
        }
      }
      .inputsWrpr {
        width: 50%;
        .inputWrpr {
          margin-bottom: 18px;

          .input {
            height: 58px;
            width: 100%;
            display: block;
            border: 1px solid #428fcc;
            border-radius: 4px;
            padding: 20px 30px;
            color: #1e6196;
            font-family: "mont med";
            &::placeholder {
              color: #94c0e2;
              font-family: "mont reg";
            }
          }

          textarea.input {
            height: 110px;
            resize: none;
          }
        }

        .submitBtn {
          background-color: #1e6196;
          color: #fff;
          border: none;
          height: 52px;
          width: 184px;
          border-radius: 4px;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .contact {
    .formWrpr {
      padding: 78px 20px;

      .formInnerWrpr {
        flex-flow: column;
        padding: 40px;

        &::before {
          // background-color: red;
          height: 76px;
          width: 76px;
          background-size: 100%;
        }

        .infoWrpr {
          width: 100%;
          padding-bottom: 18px;

          h3 {
            margin: 24px 0px 30px;
            font-size: 24px;
          }

          p.label {
            font-size: 12px;
          }
          p.value {
            font-size: 11px;
            margin-bottom: 22px;
          }
        }

        .inputsWrpr {
          width: 100%;

          .submitBtn {
            width: 100%;
            margin-top: 22px;
          }
        }
      }
    }
  }
}
